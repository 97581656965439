import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import {
  ICTANav,
  IMegaNav,
  IModelNav,
  INav,
  INavIconGroup,
  MegaNavContextType,
} from './store';
import styles from './index.module.scss';
import { ModelNav, ModelNavMobile } from './components/model-nav';
import { Nav, NavMobile } from './components/nav';
import { IconGroupNav } from './components/nav-icon-group';
import { useMediaQuery } from 'react-responsive';
import { colorBlack, mobileMax, tabletMax, toCamelCase } from '~/common/utils';
import { Modal, SvgIcon } from '~/common/components/ui-elements';
import { RecoilRoot, useRecoilState } from 'recoil';
import { UserLocation, UserLocationDealerState } from '~/common/store';
import { getDealerByPostcode } from '~/common/services/dealer-service';
import { LocationSelectedEvent, Suburb } from '~/common/models';
import _ from 'lodash';
import { PortalProvider, usePortals } from 'react-portal-hook';

export const MegaNavContext = React.createContext<MegaNavContextType | null>(
  null
);

const CallToActionNav = (props: ICTANav & { idx: number }) => {
  const { openIndex, setOpenIndex, isMobile } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const [active, setActive] = useState(false);

  return (
    <div
      className={cn(styles.MegaNavItemBlock, styles.CallToActionNav, {
        [styles.CallToActionNavMobile]: isMobile,
      })}
    >
      <a
        className={cn(styles.MainNav, styles.CallToActionNavLink, {
          [styles.NavActive]: active || openIndex === props.idx,
        })}
        href={props.ctaUrl?.url}
        target={props.ctaUrl?.target || '_self'}
        onClick={() => {
          setOpenIndex(-1);
          if ((props.ctaUrl?.target || '_self') === '_self') {
            setActive(true);
          }
        }}
      >
        {isMobile && (
          <SvgIcon
            type={toCamelCase(props.ctaIcon) as any}
            color={`#${colorBlack}`}
          />
        )}
        <span>{props.ctaUrl?.name}</span>
      </a>
    </div>
  );
};

const MegaNavContent = memo((props: IMegaNav) => {
  const { logo, navItemsLeft, navItemsRight } = props;
  const { isMobile, isTablet } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const [dealer, setDealer] = useRecoilState(UserLocationDealerState);
  const [suburb, setSuburb] = useState<Suburb>();

  const dealerChanged = useCallback(
    async (e: LocationSelectedEvent) => {
      if (e.detail && !_.isEqual(e.detail.suburb, suburb)) {
        setDealer(await getDealerByPostcode(e.detail.suburb.postcode));
        setSuburb(e.detail.suburb);
      }
    },
    [dealer]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('dealerChanged', dealerChanged);

      const _suburb = localStorage.getItem('suburb');
      if (!!_suburb) {
        const location = JSON.parse(_suburb) as UserLocation;
        const param = { detail: { suburb: location } };
        window.dispatchEvent(new CustomEvent('dealerChanged', param));
      }
    }

    return () => {
      window.removeEventListener('dealerChanged', dealerChanged);
    };
  }, [dealerChanged]);

  const renderMegaNavItem = (
    className: string,
    items: typeof navItemsLeft | typeof navItemsRight,
    idxStart = 0
  ) => {
    return (
      <div className={className}>
        {items?.map((item, idx) => (
          <Fragment key={idx + idxStart}>
            {item.contentType === 'Nav' ? (
              <Nav {...{ ...item, idx: idx + idxStart }} />
            ) : item.contentType === 'ModelNav' ? (
              <ModelNav
                {...{ ...item, idx: idx + idxStart, isMobile, isTablet }}
              />
            ) : item.contentType === 'CallToAction' ? (
              <CallToActionNav
                {...{ ...(item as ICTANav), idx: idx + idxStart }}
              />
            ) : item.contentType === 'NavIconGroup' ? (
              <IconGroupNav {...{ ...item, idx: idx + idxStart }} />
            ) : (
              <></>
            )}
          </Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className={cn('full-device-width', styles.MegaNavContainer)}>
      <div className={styles.MegaNav}>
        {renderMegaNavItem(cn(styles.MegaNavItem), navItemsLeft)}
        <div className={cn(styles.MegaNavItem, styles.MegaNavLogo)}>
          <a className={styles.MegaNavLogoLink} href="/" target="_self">
            <img src={logo} />
          </a>
        </div>
        {renderMegaNavItem(
          cn(styles.MegaNavItem),
          navItemsRight,
          navItemsLeft?.length // adjust the index start
        )}
        <div className={styles.MegaNavDealer}>
          {dealer.name && (
            <a href={`/dealers/${dealer.uniqueName}`}>{dealer.name}</a>
          )}
        </div>
      </div>
    </div>
  );
});

interface ModalProps {
  closeModal: () => void;
  icons: INavIconGroup[];
  menuItems: (INav | ICTANav | INavIconGroup)[];
}

const MegaNavMobileModalContent: React.FC<ModalProps> = ({
  closeModal,
  icons,
  menuItems,
}) => {
  const { openMobileDialogIdx } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;

  useEffect(() => {
    if (openMobileDialogIdx < 0) {
      closeModal();
    }
  }, [openMobileDialogIdx]);

  return (
    <>
      <div>
        {menuItems.length > 0 &&
          menuItems.map((i, idx) => (
            <Fragment key={idx}>
              {i.contentType === 'Nav' ? (
                <NavMobile {...{ ...i, idx }} />
              ) : i.contentType === 'ModelNav' ? (
                <ModelNavMobile {...{ ...i, idx }} />
              ) : i.contentType === 'CallToAction' ? (
                <CallToActionNav {...{ ...(i as ICTANav), idx }} />
              ) : (
                <></>
              )}
            </Fragment>
          ))}
      </div>
      <div>
        {icons.length > 0 &&
          icons.map((i, idx) => <IconGroupNav key={idx} {...{ ...i, idx }} />)}
      </div>
    </>
  );
};

const MegaNavMobileModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal
      type="media"
      closeModal={props.closeModal}
      modalContainerClass={styles.MobileModalContainer}
      modalContentClass={styles.MobileModalContent}
      modalWrapperClass={styles.MobileModalWrapper}
      closeClickModal={false}
      lockScroll
      hideClose
    >
      <MegaNavMobileModalContent {...props} />
    </Modal>
  );
};

const MegaNavMobileContent = memo((props: IMegaNav) => {
  const { logo, navItemsLeft, navItemsRight } = props;
  const { openMobileDialogIdx, setOpenMobileDialogIdx, openIndex } =
    React.useContext(MegaNavContext) as MegaNavContextType;
  const [selectedMenu, setSelectedMenu] = useState<string>();

  const menuItems = [
    ...(navItemsLeft?.filter((x) => x.contentType !== 'NavIconGroup') || []),
    ...(navItemsRight?.filter((x) => x.contentType !== 'NavIconGroup') || []),
  ];

  useEffect(() => {
    if (openMobileDialogIdx < 1) {
      setSelectedMenu(undefined);
    }
  }, [openMobileDialogIdx]);

  useEffect(() => {
    if (openIndex > -1 && menuItems[openIndex]) {
      const navItem: IModelNav & INav = menuItems[openIndex];
      setSelectedMenu(navItem.text);
    }
  }, [openIndex]);

  const _icons: INavIconGroup[] = [
    ...(navItemsRight?.filter((x) => x.contentType === 'NavIconGroup') || []),
  ];
  const portalManager = usePortals();
  const parentRef = useRef<HTMLDivElement>(null);

  const showModal = () => {
    portalManager.open(
      (portal) => (
        <MegaNavMobileModal
          closeModal={portal.close}
          icons={_icons}
          menuItems={menuItems}
        />
      ),
      parentRef.current ? { appendTo: parentRef.current } : {}
    );
  };

  return (
    <div className={cn('full-device-width', styles.MegaNavContainer)}>
      <div className={styles.MegaNavMobile}>
        <div className={cn(styles.MegaNavItem)}>
          <div
            ref={parentRef}
            className={cn(styles.MegaNavItemBlock, styles.IconNavItem)}
          >
            {openMobileDialogIdx < 0 ? (
              <SvgIcon
                type="menu"
                size={1.7}
                color={`#${colorBlack}`}
                onClick={() => {
                  setOpenMobileDialogIdx(openMobileDialogIdx < 0 ? 0 : -1);
                  showModal();
                }}
              />
            ) : openMobileDialogIdx === 0 ? (
              <SvgIcon
                type="close"
                size={2.2}
                color={`#${colorBlack}`}
                onClick={() => setOpenMobileDialogIdx(-1)}
              />
            ) : (
              <SvgIcon
                type="chevronLeft"
                size={1.7}
                color={`#${colorBlack}`}
                onClick={() => setOpenMobileDialogIdx(0)}
              />
            )}
          </div>
        </div>
        <div
          className={cn(
            styles.MegaNavItem,
            styles.MegaNavLogo,
            styles.MegaNavMobileLogo
          )}
        >
          {selectedMenu ? (
            <p>{selectedMenu}</p>
          ) : (
            <a className={styles.MegaNavLogoLink} href="/" target="_self">
              <img src={logo} />
            </a>
          )}
        </div>
        <div className={cn(styles.MegaNavItem, styles.MegaNavItemMobileRight)}>
          {_icons.length > 0 && openMobileDialogIdx < 1 ? (
            _icons.map((i, idx) => (
              <IconGroupNav
                key={idx}
                {...{ ...i, idx, displayOnMegaNavMobile: true }}
              />
            ))
          ) : (
            <SvgIcon
              type="close"
              size={2.2}
              color={`#${colorBlack}`}
              onClick={() => {
                setOpenMobileDialogIdx(0);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export const MegaNav = memo((props: IMegaNav) => {
  const [openIndex, setOpenIndex] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const [urlBasedIndex, setUrlBasedIndex] = useState(-1);
  const [openMobileDialogIdx, setOpenMobileDialogIdx] = useState(-1);

  const [isTablet, setIsTablet] = useState(
    useMediaQuery(
      { query: `(max-width: ${tabletMax}px)` },
      undefined,

      (matches) => {
        setIsTablet(matches);
      }
    )
  );

  const [isMobile, setIsMobile] = useState(
    useMediaQuery(
      { query: `(max-width: ${mobileMax}px)` },
      undefined,

      (matches) => {
        setIsMobile(matches);
      }
    )
  );

  useEffect(() => setOpenIndex(urlBasedIndex), [urlBasedIndex]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      {loaded && (
        <MegaNavContext.Provider
          value={{
            openIndex,
            setOpenIndex,
            urlBasedIndex,
            setUrlBasedIndex,
            openMobileDialogIdx,
            setOpenMobileDialogIdx,
            isMobile,
            isTablet,
          }}
        >
          <PortalProvider>
            <RecoilRoot>
              <div
                id={`${props.id}`}
                className={cn(styles.MegaNavComponent, {
                  [styles.MegaNavMobileComponent]: isMobile,
                })}
              >
                {isMobile ? (
                  <MegaNavMobileContent {...{ ...props }} />
                ) : (
                  <MegaNavContent {...{ ...props }} />
                )}
              </div>
            </RecoilRoot>
          </PortalProvider>
        </MegaNavContext.Provider>
      )}
    </>
  );
});
