import React, { CSSProperties, Fragment, memo } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { colorPrimary, toCamelCase } from '~/common/utils';
import {
  Button,
  ButtonProps,
  ButtonTypeEnum,
  IconEnum,
  SvgIcon,
} from '../../../../common/components/ui-elements';
import { CallToAction } from '~/common/models';
import { MegaNavContext } from '../..';
import { MegaNavContextType } from '../../store';

const MegaNavCtas = memo(({ ctas }: { ctas?: CallToAction[] }) => {
  const { isMobile } = React.useContext(MegaNavContext) as MegaNavContextType;

  const _contentCTAs = ctas?.filter(
    (x) =>
      !x.ctaHidden &&
      !(isMobile && x.ctaHideInMobile) &&
      !(!isMobile && x.ctaHideInDesktop)
  );

  const mapButtonSizeStyle = (cta: string): CSSProperties => {
    switch (cta.toLowerCase()) {
      case 'small':
        return { minWidth: 'calc(25% - 10px)' };
      case 'medium':
        return { minWidth: 'calc(50% - 10px)' };
      case 'large':
        return { minWidth: 'calc(75% - 10px)' };
      default:
        return {};
    }
  };

  return (
    <div className={styles.MegaNavCtas}>
      {_contentCTAs && _contentCTAs.length > 0 && (
        <>
          {_contentCTAs?.map((cta, idx) => {
            const _style: CSSProperties = {
              ...(cta.ctaColor ? { color: `#${cta.ctaColor}` } : {}),
              ...(cta.ctaColor ? { borderColor: `#${cta.ctaColor}` } : {}),
              ...mapButtonSizeStyle(cta.ctaButtonSize || ''),
            };
            const _target = cta.ctaUrl?.target || '_self';
            const buttonProps: ButtonProps = {
              type: toCamelCase(
                cta.ctaButtonType
              ) as keyof typeof ButtonTypeEnum,
              href: cta.ctaUrl?.url,
              target: _target,
              style: _style,
              buttonWidth: cta.ctaFullWidth,
            };

            const renderCTA = () => (
              <div
                key={idx}
                className={styles.MegaNavCtaContainer}
                style={{ textAlign: cta.ctaButtonAlignment }}
              >
                <Button
                  key={idx}
                  className={cn(
                    styles.MegaNavCta,
                    styles[`MegaNavCta${buttonProps.type}`],
                    {
                      [styles.MegaNavCtaColor]: !!cta.ctaColor,
                    }
                  )}
                  buttonSize="medium"
                  {...buttonProps}
                >
                  {cta.ctaIcon &&
                    cta.ctaIcon !== 'none' &&
                    (!cta.ctaIconPlacement ||
                      cta.ctaIconPlacement === 'left') && (
                      <SvgIcon
                        type={toCamelCase(cta.ctaIcon) as keyof typeof IconEnum}
                        color={`#${cta.ctaColor || colorPrimary}`}
                        size={1}
                        strokeWidth={2}
                        style={{
                          padding: '0px 5px 1.5px 0px',
                          alignSelf: 'start',
                          lineHeight: 'unset',
                        }}
                      />
                    )}
                  <span
                    className={styles.MegaNavCtaText}
                    style={
                      buttonProps.type === 'text'
                        ? {
                            color: `#${cta.ctaColor || colorPrimary}`,
                            textDecoration: 'underline',
                            textDecorationColor: `#${
                              cta.ctaColor || colorPrimary
                            }`,
                          }
                        : {}
                    }
                  >
                    {cta.ctaUrl?.name || ''}
                  </span>
                  {cta.ctaIcon &&
                    cta.ctaIcon !== 'none' &&
                    cta.ctaIconPlacement &&
                    cta.ctaIconPlacement === 'right' && (
                      <SvgIcon
                        type={toCamelCase(cta.ctaIcon) as keyof typeof IconEnum}
                        color={`#${cta.ctaColor || colorPrimary}`}
                        strokeWidth={2}
                        style={{
                          padding: '0px 0px 1.5px 2px',
                          alignSelf: 'end',
                          lineHeight: 'unset',
                        }}
                      />
                    )}
                </Button>
              </div>
            );

            return <Fragment key={idx}>{renderCTA()}</Fragment>;
          })}
        </>
      )}
    </div>
  );
});

export default MegaNavCtas;
