import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import {
  IModelCategory,
  IModelNav,
  IModelNavItem,
  IUsedCarsItem,
  MegaNavContextType,
} from '../../store';
import styles from './index.module.scss';
import ancestorStyles from '../../index.module.scss';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '~/common/components/ui-elements/floating-dialog';
import { MegaNavContext } from '../..';
import { HeroCardBlock } from '~/macros/hero-card-v2';
import MegaNavCtas from '../ctas';
import { IconEnum, Modal, SvgIcon } from '~/common/components/ui-elements';
import {
  colorBlack,
  convertObjectFromJSON,
  toCamelCase,
  toPascalCase,
} from '~/common/utils';
import digitalData from '~/common/services/data-layer-service';
import { DynamicTag } from '~/common/components/ui-elements/dynamic-tag';
import { usePortals } from 'react-portal-hook';
import { CallToAction, Link } from '~/common/models';

export const ModelNav = memo((props: IModelNav & { idx: number }) => {
  const { openIndex, setOpenIndex, isTablet } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [selectedSectionIdx, setSelectedSectionIdx] = useState(0);
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (open) {
      digitalData.pushWithCommonData(
        {
          event: '_formNavigate',
          form: {
            name: 'mega nav',
            stage: 'models',
          },
        },
        true
      );
    }
  }, [open]);

  const handleModelMouseEnter = (model: string, idx: number) => {
    const el = document.getElementById(
      `ModelNavItemSectionContent_${props.idx}_${idx}_${toPascalCase(model)}`
    );
    const contents = [].slice.call(
      document.getElementsByClassName(`ModelNavItemSection_${props.idx}_${idx}`)
    );
    contents.forEach((i) => {
      if (i) {
        const _i = i as HTMLElement;
        _i.style.display = 'none';
      }
    });
    if (el) {
      el.style.display = '';
    }
  };

  const renderModels = (items?: IModelNavItem[]) => {
    const usedCarsSectionIdx = items?.length ?? 0;

    const hasUsedCarsItems =
      !props.hideUsedCarsSubmenu &&
      props.usedCarsItems?.length &&
      props.usedCarsItems?.length > 0;

    return (
      <>
        {items?.map((i, idx) => (
          <div
            key={idx}
            className={cn(styles.ModelNavItem, {
              [styles.ModelNavItemActive]: idx === selectedIdx,
              [styles.ModelNavItemHidden]:
                !i.categories?.includes(category) && category,
            })}
            onMouseEnter={() => {
              if (i.modelName && selectedIdx !== idx) {
                handleModelMouseEnter(i.modelName, idx);
                setSelectedIdx(idx);
              }
            }}
          >
            <img src={i.imageSrc} />
            <div>
              <div>{i.modelName}</div>
              {i.subheading &&
                !i.categoriesToHideSubheading?.includes(category) && (
                  <div>{i.subheading}</div>
                )}
            </div>
          </div>
        ))}
        {!props.hideUsedCars && (
          <DynamicTag
            as={!hasUsedCarsItems ? 'a' : 'div'}
            key={usedCarsSectionIdx}
            href={!hasUsedCarsItems ? props.usedCarsUrl?.url : undefined}
            target={
              !hasUsedCarsItems
                ? props.usedCarsUrl?.target || '_self'
                : undefined
            }
            className={cn(styles.ModelNavItem, {
              [styles.ModelNavItemActive]: usedCarsSectionIdx === selectedIdx,
              [styles.UsedCars]: !hasUsedCarsItems,
            })}
            onMouseEnter={() => {
              if (selectedIdx !== usedCarsSectionIdx) {
                handleModelMouseEnter('UsedCars', usedCarsSectionIdx);
                setSelectedIdx(usedCarsSectionIdx);
              }
            }}
          >
            <img src={props.usedCarsImageSrc} />
            <div>{props?.usedCarsUrl?.name}</div>
          </DynamicTag>
        )}
        <div className={styles.ModelNavCtas}>
          <MegaNavCtas ctas={props.ctas} />
        </div>
      </>
    );
  };

  const renderSections = () => {
    const usedCarsSectionIdx = props.items?.length ?? 0;
    return (
      <>
        {props.items?.map((i, idx) => (
          <div
            key={idx}
            id={`ModelNavItemSectionContent_${props.idx}_${idx}_${
              i.modelName ? toPascalCase(i.modelName) : ''
            }`}
            className={cn(
              `ModelNavItemSection_${props.idx}_${idx}`,
              styles.ModelNavItemSection
            )}
            style={{
              ...(idx === selectedIdx ? '' : { display: 'none' }),
            }}
          >
            <div>
              <a href={i.link?.url} target={i.link?.target || '_self'}>{`${
                i.modelName
              }${
                i.subheading &&
                !i.categoriesToHideSubheading?.includes(category)
                  ? ` (${i.subheading})`
                  : ''
              }`}</a>
            </div>
            {i.items?.map((j, idx2) => (
              <a
                key={idx2}
                className={cn(styles.ItemLink)}
                href={j?.url}
                target={j?.target || '_self'}
              >
                {j?.name}
              </a>
            ))}
            <MegaNavCtas ctas={i.ctas} />
          </div>
        ))}
        {!props.hideUsedCarsSubmenu &&
          props.usedCarsItems &&
          props.usedCarsItems.length > 0 && (
            <div
              key={usedCarsSectionIdx}
              id={`ModelNavItemSectionContent_${props.idx}_${usedCarsSectionIdx}_UsedCars`}
              className={cn(
                `ModelNavItemSection_${props.idx}_${usedCarsSectionIdx}`,
                styles.ModelNavItemSection
              )}
              style={{
                ...(usedCarsSectionIdx === selectedIdx
                  ? ''
                  : { display: 'none' }),
              }}
            >
              <div> {props.usedCarsUrl?.name ?? 'Used Cars'}</div>
              {props.usedCarsItems?.map((j, idx2) => {
                const item =
                  j.contentType === 'UsedCarsItem'
                    ? convertObjectFromJSON<IUsedCarsItem>(JSON.stringify(j))
                    : {};
                return (
                  <Fragment key={idx2}>
                    {j.contentType === 'UsedCarsItem' && (
                      <a
                        className={cn(styles.ItemLink)}
                        href={item.link?.url}
                        target={item.link?.target || '_self'}
                        onMouseEnter={() => {
                          setSelectedSectionIdx(idx2);
                        }}
                      >
                        {item.link?.name}
                      </a>
                    )}
                  </Fragment>
                );
              })}
              <MegaNavCtas ctas={props.usedCarsCtas} />
            </div>
          )}
      </>
    );
  };

  const renderSectionPreviews = (items?: IModelNavItem[]) => {
    const usedCarsSectionIdx = items?.length ?? 0;
    return (
      <>
        {items?.map((i, idx) => (
          <Fragment key={idx}>
            {idx === selectedIdx && (
              <div className={cn(styles.ModelNavItemSectionPreview)}>
                <HeroCardBlock areas={i.preview} _gridColumnCount={12} />
              </div>
            )}
          </Fragment>
        ))}
        {props.usedCarsItems?.map((j, idx) => {
          const item =
            j.contentType === 'UsedCarsItem'
              ? convertObjectFromJSON<IUsedCarsItem>(JSON.stringify(j))
              : {};

          if (idx !== selectedSectionIdx || usedCarsSectionIdx !== selectedIdx)
            return <></>;

          return (
            <Fragment key={idx}>
              {j.contentType === 'UsedCarsItem' && (
                <div className={cn(styles.ModelNavItemSectionPreview)}>
                  <HeroCardBlock areas={item.preview} _gridColumnCount={12} />
                </div>
              )}
            </Fragment>
          );
        })}
      </>
    );
  };

  const renderCategories = (
    categories?: IModelCategory[],
    items: IModelNavItem[] = []
  ) => {
    if (!categories || categories?.length === 0) return <></>;

    const handleCategoryChange = (c: string) => {
      setCategory(c);
      const showIdx = items.findIndex((f) => f.categories?.includes(c) || !c);
      setSelectedIdx(showIdx);
    };

    return (
      <>
        {categories.map((c, idx) => (
          <div key={idx} className={styles.Category}>
            <DynamicTag
              as={c.link?.url ? 'a' : 'div'}
              href={c.link?.url ? c.link.url : undefined}
              target={c.link?.url ? c.link.target || '_self' : undefined}
              className={cn(styles.CategoryText, {
                [styles.CategoryTextActive]:
                  category === c.text || (!category && idx === 0),
              })}
              onMouseEnter={() => handleCategoryChange(c.text)}
            >
              {c.text}
            </DynamicTag>
          </div>
        ))}
      </>
    );
  };

  return (
    <div
      ref={ref}
      className={cn(ancestorStyles.MegaNavItemBlock, styles.ModelNav)}
    >
      <Dialog
        open={open && props.idx === openIndex}
        onOpenChange={setOpen}
        root={ref.current}
      >
        <DialogTrigger
          className={cn(ancestorStyles.MainNav, styles.DialogTriggerOverride, {
            [ancestorStyles.NavActive]: openIndex === props.idx,
          })}
          onMouseEnter={() => {
            setOpenIndex(props.idx);
            setOpen(true);
          }}
          onClickComplete={() => {
            setOpenIndex(props.idx);
            setOpen(true);
          }}
        >
          {props.text}
        </DialogTrigger>
        <DialogContent
          className={styles.DialogOverlayOverride}
          contentClassName={styles.DialogOverlayContentOverride}
          disableFocusOnDialog
          hideCloseButton
        >
          <div className={styles.ModelNavCategories}>
            {renderCategories(props.categories, props.items)}
          </div>
          <div className={styles.ModelNavContents}>
            <div className={styles.ModelNavItems}>
              {renderModels(props.items)}
            </div>
            <div className={styles.ModelNavItemSections}>
              {renderSections()}
            </div>
            {!isTablet && (
              <div className={styles.ModelNavItemSectionPreviews}>
                {renderSectionPreviews(props.items)}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

interface ModalProps {
  closeModal: () => void;
  items: IModelNavItem[];
  categories: IModelCategory[];
  usedCarsUrl?: Link;
  ctas?: CallToAction[];
}

const ModelNavMobileModalContent: React.FC<ModalProps> = ({
  closeModal,
  categories,
  items,
  ctas,
  usedCarsUrl,
}) => {
  const { openMobileDialogIdx } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (openMobileDialogIdx < 1) {
      closeModal();
    }
  }, [openMobileDialogIdx]);

  const renderModels = (_items?: IModelNavItem[]) => {
    if (!_items || _items?.length === 0) return <></>;

    return (
      <>
        {_items?.map((i, idx) => (
          <div
            key={idx}
            className={cn(styles.ModelNavItem, {
              [styles.ModelNavItemHidden]:
                !i.categories?.includes(category) && category,
            })}
          >
            <div>
              <img src={i.imageSrc} />
              <a href={i.link?.url} target={i.link?.target || '_self'}>
                <div>
                  <div>{`${i.modelName}`}</div>
                  {i.subheading && <div>{i.subheading}</div>}
                </div>
              </a>
            </div>
          </div>
        ))}
        <a
          key={_items.length}
          href={usedCarsUrl?.url}
          target={usedCarsUrl?.target || '_self'}
          className={cn(styles.ModelNavItem, styles.UsedCars)}
        >
          {usedCarsUrl?.name}
        </a>
        <MegaNavCtas ctas={ctas} />
      </>
    );
  };

  const renderCategories = (_categories?: IModelCategory[]) => {
    if (!_categories || _categories?.length === 0) return <></>;

    const handleCategoryClick = (c: string) => setCategory(c);

    return (
      <>
        {_categories.map((c, idx) => (
          <div key={idx} className={styles.Category}>
            <DynamicTag
              as={c.link?.url ? 'a' : 'div'}
              href={c.link?.url ? c.link.url : undefined}
              target={c.link?.url ? c.link.target || '_self' : undefined}
              className={cn(styles.CategoryText, {
                [styles.CategoryTextActive]:
                  category === c.text || (!category && idx === 0),
              })}
              onClick={() => handleCategoryClick(c.text)}
            >
              {c.text}
            </DynamicTag>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div className={styles.ModelNavCategories}>
        {renderCategories(categories)}
      </div>
      <div className={styles.ModelNavContents}>
        <div className={styles.ModelNavItems}>{renderModels(items)}</div>
      </div>
    </>
  );
};

const ModelNavMobileModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal
      type="media"
      closeModal={props.closeModal}
      modalContainerClass={styles.MobileModalContainer}
      modalContentClass={styles.MobileModalContent}
      modalWrapperClass={styles.MobileModalWrapper}
      closeClickModal={false}
      lockScroll
      hideClose
    >
      <ModelNavMobileModalContent {...props} />
    </Modal>
  );
};

export const ModelNavMobile = memo((props: IModelNav & { idx: number }) => {
  const { setOpenMobileDialogIdx, setOpenIndex } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;

  const portalManager = usePortals();
  const parentRef = useRef<HTMLDivElement>(null);

  const showModal = () => {
    portalManager.open(
      (portal) => (
        <ModelNavMobileModal
          closeModal={portal.close}
          items={props.items || []}
          categories={props.categories || []}
          ctas={props.ctas}
          usedCarsUrl={props.usedCarsUrl}
        />
      ),
      parentRef.current ? { appendTo: parentRef.current } : {}
    );
  };

  return (
    <div className={cn(styles.ModelNavMobile)}>
      <div
        className={styles.ModelNavMobileTrigger}
        onClick={() => {
          showModal();
          setOpenMobileDialogIdx(1);
          setOpenIndex(props.idx);
          digitalData.pushWithCommonData(
            {
              event: '_formNavigate',
              form: {
                name: 'mega nav',
                stage: 'models',
              },
            },
            true
          );
        }}
      >
        <div>
          {props.icon && (
            <SvgIcon
              type={toCamelCase(props.icon) as keyof typeof IconEnum}
              color={`#${colorBlack}`}
            />
          )}
        </div>
        <div>{props.text}</div>
        <div>
          {props.icon && (
            <SvgIcon type="chevronRight" size={1.1} color={`#${colorBlack}`} />
          )}
        </div>
      </div>
    </div>
  );
});
