import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { IBasicRte, INav, INavItem, MegaNavContextType } from '../../store';
import styles from './index.module.scss';
import ancestorStyles from '../../index.module.scss';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '~/common/components/ui-elements/floating-dialog';
import { MegaNavContext } from '../..';
import { HeroCardBlock } from '~/macros/hero-card-v2';
import MegaNavCtas from '../ctas';
import {
  colorBlack,
  convertObjectFromJSON,
  removeSpecialCharacters,
  toCamelCase,
  toPascalCase,
} from '~/common/utils';
import { IconEnum, Modal, SvgIcon } from '~/common/components/ui-elements';
import digitalData from '~/common/services/data-layer-service';
import { usePortals } from 'react-portal-hook';
import { CallToAction } from '~/common/models';

export const Nav = memo((props: INav & { idx: number }) => {
  const { openIndex, setOpenIndex } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(
    props.items?.findIndex((x) => x.contentType === 'NavItem')
  );

  useEffect(() => {
    if (open) {
      digitalData.pushWithCommonData(
        {
          event: '_formNavigate',
          form: {
            name: 'mega nav',
            stage: removeSpecialCharacters(props.text),
          },
        },
        true
      );
    }
  }, [open]);

  const renderSections = () => {
    if (!props.items || props.items?.length === 0) return <></>;
    return (
      <>
        {props.items?.map((i, idx) => {
          const item =
            i.contentType === 'NavItem'
              ? convertObjectFromJSON<INavItem>(JSON.stringify(i))
              : i.contentType === 'BasicRte'
              ? convertObjectFromJSON<INavItem>(JSON.stringify(i))
              : {};

          return (
            <Fragment key={idx}>
              {item.contentType === 'NavItem' ? (
                <a
                  id={`NavItemSectionContent_${props.idx}_${idx}_${toPascalCase(
                    item.link?.name
                  )}`}
                  className={cn(styles.NavItemSection, {
                    [styles.NavItemSectionActive]: idx === selectedIdx,
                  })}
                  onMouseEnter={() => {
                    setSelectedIdx(idx);
                  }}
                  href={item.link?.url}
                  target={item.link?.target || '_self'}
                >
                  {item.link?.name}
                </a>
              ) : i.contentType === 'BasicRte' ? (
                <div
                  className={cn(
                    styles.NavItemSection,
                    styles.NavItemSectionRte
                  )}
                  dangerouslySetInnerHTML={{
                    __html: (i as IBasicRte).text as string,
                  }}
                />
              ) : (
                <></>
              )}
            </Fragment>
          );
        })}
        <div className={styles.NavItemSectionCtas}>
          <MegaNavCtas ctas={props.ctas} />
        </div>
      </>
    );
  };

  const renderSectionPreviews = (items?: INavItem[]) => {
    if (!items || items?.length === 0) return <></>;
    return (
      <>
        {items?.map((i, idx) => (
          <Fragment key={idx}>
            {idx === selectedIdx && (
              <div className={cn(styles.NavItemSectionPreview)}>
                <HeroCardBlock areas={i.preview} _gridColumnCount={12} />
              </div>
            )}
          </Fragment>
        ))}
      </>
    );
  };

  return (
    <div ref={ref} className={cn(ancestorStyles.MegaNavItemBlock, styles.Nav)}>
      <Dialog
        open={open && props.idx === openIndex}
        onOpenChange={setOpen}
        root={ref.current}
      >
        <DialogTrigger
          className={cn(ancestorStyles.MainNav, styles.DialogTriggerOverride, {
            [ancestorStyles.NavActive]: openIndex === props.idx,
          })}
          onMouseEnter={() => {
            setOpenIndex(props.idx);
            setOpen(true);
          }}
          onClickComplete={() => {
            setOpenIndex(props.idx);
            setOpen(true);
          }}
        >
          {props.text}
        </DialogTrigger>
        <DialogContent
          className={styles.DialogOverlayOverride}
          contentClassName={styles.DialogOverlayContentOverride}
          disableFocusOnDialog
          hideCloseButton
        >
          <div className={styles.NavContents}>
            <div className={styles.NavItemSections}>{renderSections()}</div>
            <div className={styles.NavItemSectionPreviews}>
              {renderSectionPreviews(props.items)}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
});

interface ModalProps {
  closeModal: () => void;
  items: (IBasicRte | INavItem)[];
  index: number;
  ctas?: CallToAction[];
}

const NavMobileModalContent: React.FC<ModalProps> = ({
  closeModal,
  items,
  index,
  ctas,
}) => {
  const { openMobileDialogIdx } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;

  useEffect(() => {
    if (openMobileDialogIdx < 1) {
      closeModal();
    }
  }, [openMobileDialogIdx]);

  return (
    <>
      {items?.map((i, idx) => {
        const item =
          i.contentType === 'NavItem'
            ? convertObjectFromJSON<INavItem>(JSON.stringify(i))
            : i.contentType === 'BasicRte'
            ? convertObjectFromJSON<INavItem>(JSON.stringify(i))
            : {};

        return (
          <Fragment key={idx}>
            {item.contentType === 'NavItem' ? (
              <a
                id={`NavItemSectionContent_${index}_${idx}_${toPascalCase(
                  item.link?.name
                )}`}
                className={cn(styles.NavItemSection)}
                href={item.link?.url}
                target={item.link?.target || '_self'}
              >
                {item.link?.name}
              </a>
            ) : i.contentType === 'BasicRte' ? (
              <div
                className={cn(styles.NavItemSection, styles.NavItemSectionRte)}
                dangerouslySetInnerHTML={{
                  __html: (i as IBasicRte).text as string,
                }}
              />
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
      <div className={styles.NavItemSectionCtas}>
        <MegaNavCtas ctas={ctas} />
      </div>
    </>
  );
};

const NavMobileModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal
      type="media"
      closeModal={props.closeModal}
      modalContainerClass={styles.MobileModalContainer}
      modalContentClass={styles.MobileModalContent}
      modalWrapperClass={styles.MobileModalWrapper}
      closeClickModal={false}
      lockScroll
      hideClose
    >
      <NavMobileModalContent {...props} />
    </Modal>
  );
};

export const NavMobile = memo((props: INav & { idx: number }) => {
  const { setOpenMobileDialogIdx, setOpenIndex } = React.useContext(
    MegaNavContext
  ) as MegaNavContextType;

  const portalManager = usePortals();
  const parentRef = useRef<HTMLDivElement>(null);

  const showModal = () => {
    portalManager.open(
      (portal) => (
        <NavMobileModal
          closeModal={portal.close}
          index={props.idx}
          items={props.items || []}
          ctas={props.ctas}
        />
      ),
      parentRef.current ? { appendTo: parentRef.current } : {}
    );
  };

  return (
    <div ref={parentRef} className={cn(styles.NavMobile)}>
      <div
        className={styles.NavMobileTrigger}
        onClick={() => {
          showModal();
          setOpenMobileDialogIdx(1);
          setOpenIndex(props.idx);
          digitalData.pushWithCommonData(
            {
              event: '_formNavigate',
              form: {
                name: 'mega nav',
                stage: removeSpecialCharacters(props.text),
              },
            },
            true
          );
        }}
      >
        <div>
          {props.icon && (
            <SvgIcon
              type={toCamelCase(props.icon) as keyof typeof IconEnum}
              color={`#${colorBlack}`}
            />
          )}
        </div>
        <div>{props.text}</div>
        <div>
          {props.icon && (
            <SvgIcon type="chevronRight" size={1.1} color={`#${colorBlack}`} />
          )}
        </div>
      </div>
    </div>
  );
});
